@import '../common/fonts';
@import '../common/colors';
@import '../common/sizes';

.features-extra, .benefits {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 96px 156px;

    h2 {
        @include font-style-caption;

        margin-bottom: 16px;
    }

    h3 {
        @include font-style-h2;
    }

    h4 {
        @include font-style-h3;
    }

    .grid {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;

        .feature {
            background-color: $color-darkish-grey;
            border-radius: 10px;
            flex: 1 1 50%;
            padding: 30px;

            h4 {
                color: $color-vivid-green;
                margin-bottom: 20px;

                .icon {
                    align-items: center;
                    background-color: $color-base-green;
                    border-radius: 50%;
                    color: $color-base-white;
                    display: flex;
                    height: 80px;
                    justify-content: center;
                    margin-bottom: 32px;
                    padding: 12px;
                    width: 80px;

                    &.alternate {
                        background-color: $color-secondary-pink;
                        color: $color-base-gray;
                    }

                    svg {
                        min-height: 33px;
                        min-width: 33px;
                    }
                }
            }
        }
    }
}

@media screen and (width < $size-M) {
    .features-extra, .benefits {
        flex-direction: column;
        gap: 30px;
        padding: 60px 30px;

        .grid {
            grid-template-columns: 1fr;

            .feature {
                h4 {
                    .icon {
                        height: 50px;
                        width: 50px;

                        svg {
                            min-height: 20px;
                            min-width: 20px;
                        }
                    }
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:90";