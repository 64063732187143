@import '../common/sizes';

@import 'https://fonts.googleapis.com/css2?family=Inter:wght@200..800&display=swap';

$font-main: inter, helvetica, 'Helvetica Neue', sans-serif;
$font-weight-extra-light: 200;
$font-weight-light: 400;
$font-weight-regular: 500;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-black: 800;


@mixin font-style-h0 {
    font-family: $font-main;
    font-size: 56px;
    font-weight: $font-weight-black;
    line-height: 120%;

    @media (width <= $size-S) {
        font-size: 30px;
    }
}

@mixin font-style-h1 {
    font-family: $font-main;
    font-size: 48px;
    font-weight: $font-weight-bold;
    line-height: 110%;

    @media (width <= $size-S) {
        font-size: 28px;
    }
}

@mixin font-style-h2 {
    font-family: $font-main;
    font-size: 40px;
    font-weight: $font-weight-bold;
    line-height: 125%;

    @media (width <= $size-S) {
        font-size: 24px;
    }
}

@mixin font-style-h3 {
    font-family: $font-main;
    font-size: 24px;
    font-weight: $font-weight-medium;
    line-height: auto;
    
    @media (width <= $size-S) {
        font-size: 18px;
    }
}

@mixin font-style-h3-light {
    font-family: $font-main;
    font-size: 24px;
    font-weight: $font-weight-light;
    line-height: 135%;
    
    @media (width <= $size-S) {
        font-size: 18px;
    }
}

@mixin font-style-h4 {
    font-family: $font-main;
    font-size: 20px;
    font-weight: $font-weight-medium;
    line-height: 24px;

    @media (width <= $size-S) {
        font-size: 16px;
    }
}

@mixin font-style-h5 {
    font-family: $font-main;
    font-size: 16px;
    font-weight: $font-weight-medium;
    line-height: 150%;

    @media (width <= $size-S) {
        font-size: 14px;
    }
}

@mixin font-style-body {
    font-family: $font-main;
    font-size: 16px;
    font-weight: $font-weight-light;
    line-height: 150%;

    @media (width <= $size-S) {
        font-size: 14px;
    }
}

@mixin font-style-body-bold {
    font-family: $font-main;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 150%;

    @media (width <= $size-S) {
        font-size: 14px;
    }
}

@mixin font-style-body-intro {
    font-family: $font-main;
    font-size: 20px;
    font-weight: $font-weight-light;
    line-height: 150%;

    @media (width <= $size-S) {
        font-size: 18px;
    }
}

@mixin font-style-body-list {
    font-family: $font-main;
    font-size: 16px;
    font-weight: $font-weight-regular;
    line-height: 150%;

    @media (width <= $size-S) {
        font-size: 14px;
    }
}

@mixin font-style-link {
    font-family: $font-main;
    font-size: 14px;
    font-weight: $font-weight-light;
    line-height: 150%;

    @media (width <= $size-S) {
        font-size: 12px;
    }
}

@mixin font-style-caption {
    font-family: $font-main;
    font-size: 16px;
    font-weight: $font-weight-medium;
    line-height: 150%;
    text-transform: uppercase;

    @media (width <= $size-S) {
        font-size: 14px;
    }
}

@mixin font-style-eyebrow {
    font-family: $font-main;
    font-size: 18px;
    font-weight: $font-weight-medium;
    line-height: 125%;

    @media (width <= $size-S) {
        font-size: 16px;
    }
}

;@import "sass-embedded-legacy-load-done:32";