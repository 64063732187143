@import '../../web/components/sprite';

.used-by-pros {
    padding-bottom: 30px;

    h4 {
        color: $color-mid-grey;
        margin: 10px 0 24px;
        text-align: center;
        @include font-style-body;
    }

    .logos {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: space-between;
        overflow-x: auto;
        padding: 32px 0 26px;

        span svg {
            flex: 1 1 auto;
            height: 36px;
        }
    }

    @media screen and (width <= $size-L) {
        .logos {
            padding: 32px 40px 26px;
        }
    }
}

.privacy-security-center + .used-by-pros {
    padding-top: 42px;
}

.plans-details + .used-by-pros {
    padding-top: 100px;
}
;@import "sass-embedded-legacy-load-done:99";