@import '../common/colors';
@import '../common/fonts';

$color-gradient-accent: darken($color-tech-blue, 40%);
$color-gradient-bg: $color-deep-green;

main {
    .privacy-security-center {
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        padding: 80px 40px;

        .card {
            background: $color-deep-green;
            background: radial-gradient(farthest-corner at 50% 100%, $color-gradient-accent 0%, $color-gradient-bg 100%);
            border-radius: 32px;
            padding: 32px 114px;

            h2 {
                @include font-style-h2;

                margin-bottom: 50px;
            }

            & > .container {
                display: flex;
                flex-direction: row;
                gap: 144px;
            }

            .badges {
                display: grid;
                gap: 30px;
                grid-template-columns: repeat(2, 1fr);
                justify-content: space-between;
                place-items: center center;

                img {
                    height: 76px;
                    width: 76px;
                }
            }

            .description {
                align-items: start;
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    @include font-style-h3-light;

                    margin-bottom: 20px;
                }

                .ms-button-transparent {
                    @include font-style-body-intro;

                    font-weight: $font-weight-bold;
                }
            }

            .icon-holder {
                display: flex;
                flex-direction: row;
                gap: 70px;
                margin-top: 6px;

                .google-cloud-partner {
                    margin-left: -25px;
                    margin-top: -22px;

                    svg {
                        height: 138px;
                        width: 138px;
                    }
                }

                .iso27001 {
                    img {
                        height: 98px;
                        width: 98px;
                    }
                }
            }
        }
    }
}

@media screen and (width <= $size-L) {
    main {
        .privacy-security-center {
            .badges {
                gap: 20px;
                justify-content: start;
            }
        }
    }
}

@media screen and (width <= $size-M) {
    main {
        .privacy-security-center {
            .card {
                gap: 40px;
                padding: 30px;

                & > .container {
                    gap: 40px;
                }

                .badges {
                    align-items: center;
                    gap: 20px;
                }

                .description {
                    display: flex;
                    flex-direction: column;
                    gap: 0;

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media screen and (width <= $size-S) {
    main .privacy-security-center .card {
        flex-direction: column;

        & > .container {
            display: flex;
            flex-direction: column;
        }
    }
    
}

;@import "sass-embedded-legacy-load-done:217";