@import '../common/colors';
@import '../common/fonts';

$color-primary-fg: $color-darkish-grey;
$color-primary-bg: $color-vivid-green;
$color-secondary-fg: $color-darkish-grey;
$color-secondary-bg: $color-tech-blue;
$color-secondary-bg-hover: $color-tech-purple;

@mixin ms-button {
    align-items: center;
    background-color: $color-primary-bg;
    border: 0;
    border-radius: 50px;
    color: $color-primary-fg;
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: $font-weight-medium;
    gap: 8px;
    justify-content: center;
    line-height: 130%;
    padding: 12px 20px;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .1s ease-out;

    &:hover {
        background-color: lighten($color-primary-bg, 20%);
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    &.hidden {
        display: none;
    }
}

@mixin ms-button-secondary {
    @include ms-button;

    background-color: $color-secondary-bg;
    color: $color-secondary-fg;

    &:hover {
        background-color: $color-secondary-bg-hover;
    }
}

@mixin ms-button-tertiary {
    @include ms-button;

    background-color: $color-secondary-bg;
    color: $color-secondary-fg;

    &:hover {
        background-color: lighten($color-secondary-bg, 10%);
    }
}

@mixin ms-button-transparent {
    @include font-style-body-bold;

    align-items: center;
    background: none;
    border-radius: 4px;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-weight: $font-weight-medium;
    padding: 8px 4px 8px 10px;
    text-decoration: none;
    text-transform: none;
    transition: background-color .1s ease-out;

    &::after {
        background: 
            url('data:image/svg+xml,%3Csvg%20width%3D%227%22%20height%3D%2211%22%20viewBox%3D%220%200%207%2011%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M0.148438%209.50317L3.97656%205.67505L0.148438%201.84692L1.32031%200.675049L6.32031%205.67505L1.32031%2010.675L0.148438%209.50317Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E')
            no-repeat
            center;
        content: '';
        display: block;
        height: 20px;
        width: 30px;
    }

    &:hover {
        background-color: transparentize($color: $color-white, $amount: .9);
    }
}

.ms-button {
    @include ms-button;
}

.ms-button-secondary {
    @include ms-button-secondary;
}

.ms-button-tertiary {
    @include ms-button-tertiary;
}

.ms-button-transparent {
    @include ms-button-transparent;
}

;@import "sass-embedded-legacy-load-done:82";