@import '../common/colors';
@import '../common/fonts';
@import '../common/sizes';

.testimonials {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 60px 154px;

    .badge-container {
        display: flex;
        gap: 40px;

        img {
            height: 80px;
        }

        img.capterra {
            height: 65px;
            margin-top: 5px;
        }
    }

    .card-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .card {
        align-self: stretch;
        background-color: $color-base-white;
        border-radius: 18px;
        color: $color-base-gray;
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        gap: 36px;
        padding: 30px;

        .header {
            align-items: center;
            display: flex;
            gap: 20px;

            img {
                border-radius: 50%;
                height: 64px;
            }

            .info {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                gap: 5px;
                justify-content: center;

                .name {
                    @include font-style-eyebrow;
                }

                .occupation {
                    font-size: 12px;
                    line-height: 130%;
                }
            }
        }

        .content {
            @include font-style-link;

            font-weight: $font-weight-light;
            text-wrap: pretty;
        }
    }

    .ratings {
        padding: 32px 0 24px;
    }

    &.wall {
        padding-bottom: 42px;

        .card-container {
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(3, minmax(300px, 1fr));
        }

        .badge-container {
            padding-top: 12px;
        }
    }

    @media screen and (max-width: $size-L) {
        padding: 80px 86px;
    }

    @media screen and (max-width: $size-M) {
        &.wall {
            padding: 80px 30px;

            .card-container {
                display: grid;
                gap: 16px;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            }
        }
    }

    @media screen and (max-width: $size-S) {
        padding: 80px 30px;

        .card-container {
            overflow-x: auto;
            padding: 0 30px;
            scroll-snap-type: x mandatory;
            width: 100vw;

            .card {
                flex: 1 0 80vw;
                scroll-snap-align: center;
            }
        }
    }
}

.used-by-pros + .testimonials {
    padding-top: 0;
}
;@import "sass-embedded-legacy-load-done:156";