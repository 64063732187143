@import '../common/colors';
@import '../common/fonts';

$color-gradient-accent: #312C91;
$color-button-disabled-solid: #b3986f;

.cta-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 92px 154px;

    h2 {
        @include font-style-h2;

        font-weight: $font-weight-light;
        text-align: center;
    }

    .description {
        color: $color-base-white;
        margin-bottom: 24px;
        @include font-style-body;
    }

    p a.disabled {
        padding-left: 56px;
        padding-right: 56px;
    }

    &.horizontal {
        align-items: start;
        background: none;
        flex-direction: row;
        position: relative;

        a {
            display: inline-block;
        }

        h2,
        .description {
            flex: 0 0 50%;
        }

        h2 {
            padding-right: 110px;
            text-align: left;
        }
    }
}

@media (width <= $size-M) {
    .cta-container {
        &.horizontal h2 {
            padding-right: 0;
        }
    }
}

@media (width <= $size-S) {
    .cta-container {
        padding: 60px 30px;

        &.horizontal {
            flex-direction: column;

            h2 {
                margin-bottom: 20px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:191";