@import '../common/fonts';
@import '../common/sizes';

.featured-in {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    padding: 64px 90px;

    &.dark {
        background-color: $color-base-purple;
        color: $color-base-white;
    }

    h4 {
        @include font-style-body;
    }

    .logos {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        flex-flow: row wrap;
        gap: 16px;
        justify-content: space-between;

        img {
            height: 25px;
        }
    }
}

@media screen and (max-width: $size-M) {
    .featured-in {
        align-items: center;
        font-size: 14px;
        gap: 48px;
        justify-content: center;
        padding: 40px 30px;

        .logos {
            flex-flow: row nowrap;
            gap: 32px;
            overflow: scroll hidden;
            white-space: nowrap;
        }
    }
}

;@import "sass-embedded-legacy-load-done:65";