@import '../common/fonts';
@import '../common/sizes';

.ratings {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 48px;

    & > div {
        text-align: center;
    }

    h5 {
        @include font-style-link;

        span {
            display: block;
            font-size: 17px;
            line-height: 120%;
        }
    }

    .star-holder img {
        height: 20px;
    }

    .store-holder {
        h5 {
            font-size: 12px;
            font-weight: $font-weight-bold;
            line-height: 18px;

            span.category {
                font-size: inherit;
                font-weight: $font-weight-medium;
                line-height: inherit;
            }

            span.store {
                font-size: 8px;
                font-weight: $font-weight-light;
                line-height: inherit;
            }
        }
    }

    &.full {
        flex-wrap: wrap;
        gap: 30px 58px;
        justify-content: center;
        padding: 12px 90px;

        h4 {
            @include font-style-h2;

            font-weight: $font-weight-light;
            text-align: start;
            width: 100%;
        }
    }

    @media screen and (max-width: $size-M) {
        .star-holder img {
            height: 18px;
        }
    }

    @media screen and (max-width: $size-S) {
        gap: 30px;

        h5 span {
            @include font-style-link;

            font-weight: $font-weight-bold;
            padding-top: 6px;
        }

        .star-holder img {
            height: 12px;
        }
    }
}


;@import "sass-embedded-legacy-load-done:110";