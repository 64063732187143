// main: ../styles.scss

$icon-width: 20px;
$icon-height: 20px;

.sprite {
    background-image: url('//' + $mailtrack_domain + '/img/mailtrack-sprite_2x.png');
    background-size: 360px 480px;
    display: inline-block;
    height: $icon-height;
    width: $icon-width;

    &.s-logo {
        background-position: (-($icon-width * 0)) (-($icon-height * 0));
        height: $icon-height * 2;
        width: $icon-width * 11;
    }

    &.s-logo-icon {
        background-position: (-($icon-width * 11)) (-($icon-height * 11));
        height: $icon-height * 3;
        width: $icon-width * 3;
    }

    &.s-company-01,
    &.s-company-02,
    &.s-company-03,
    &.s-company-04,
    &.s-company-05,
    &.s-company-06 {
        height: $icon-height * 3;
        width: $icon-width * 8;
    }

    &.s-company-01 {
        background-position: (-($icon-width * 0)) (-($icon-height * 3));
    }

    &.s-company-02 {
        background-position: (-($icon-width * 0)) (-($icon-height * 6));
    }

    &.s-company-03 {
        background-position: (-($icon-width * 0)) (-($icon-height * 9));
    }

    &.s-company-04 {
        background-position: (-($icon-width * 0)) (-($icon-height * 12));
    }

    &.s-company-05 {
        background-position: (-($icon-width * 0)) (-($icon-height * 15));
    }

    &.s-company-06 {
        background-position: (-($icon-width * 0)) (-($icon-height * 18));
    }

    &.s-card-mc,
    &.s-card-visa,
    &.s-card-amex,
    &.s-card-discover,
    &.s-card-paypal,
    &.s-card-generic {
        height: 22px;
        width: 37px;
    }

    &.s-card-mc {
        background-position: (-($icon-width * 11)) (-($icon-height * 14));
    }

    &.s-card-visa {
        background-position: (-($icon-width * 13)) (-($icon-height * 14));
    }

    &.s-card-amex {
        background-position: (-($icon-width * 15)) (-($icon-height * 14));
    }

    &.s-card-discover {
        background-position: (-($icon-width * 11)) (-($icon-height * 16));
    }

    &.s-card-paypal {
        background-position: (-($icon-width * 13)) (-($icon-height * 16));
    }

    &.s-card-generic,
    &.s-card-default {
        background-position: (-($icon-width * 15)) (-($icon-height * 16));
    }

    &.s-stripe {
        background-position: (-($icon-width * 11)) (-($icon-height * 2));
        height: 26px;
        width: 120px;
    }

    &.s-cvv {
        background-position: (-($icon-width * 11)) (-($icon-height * 7));
        height: 60px;
        width: 110px;
    }
}

;@import "sass-embedded-legacy-load-done:103";