@import '../common/colors';
@import '../common/fonts';
@import '../components/buttons';

$color-highlight: $color-vivid-green;

.features-explained {
    padding: 60px 40px 0 40px;

    .feature {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 100px;
        margin-bottom: 60px;

        &.reverse {
            flex-direction: row-reverse;
        }

        .picture-holder {
            align-items: center;
            border-radius: 24px;
            display: flex;
            flex: 0 0 50%;
            height: 600px;
            justify-content: center;
            padding: 28px 16px;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        h3 {
            @include font-style-h1;

            color: $color-highlight;
            margin-bottom: 24px;
        }

        .description {
            color: $color-light-grey;
            flex: 1 1 auto;
            font-weight: $font-weight-light;

            a {
                color: $color-white;
                font-weight: $font-weight-bold;
                text-decoration: none;
            }

            .buton-container {
                color: $color-white;
                margin-top: 10px;

                a {
                    display: inline-block;

                    &.ms-button {
                        @include ms-button-transparent;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $size-L) {
    .features-explained {
        padding: 0 40px;

        .feature {
            .description .buton-container {
                a {
                    display: inline-block;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media screen and (width <= $size-M) {
    .features-explained {
        padding: 0 30px;

        .feature,
        .feature.reverse {
            flex-direction: column;
            gap: 36px;
            margin-bottom: 100px;

            .picture-holder {
                flex: 0 0 auto;
                height: auto;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:77";