$mailtrack_domain: "mailtrack.ngrok.io/bundles/mailtrackfrontend"; $mailtrack_signature_generator_domain: "https://mailtrack-signature-generator.s3.eu-west-1.amazonaws.com";
@import '../common/colors';
@import '../common/fonts';
@import '../common/sizes';
@import '../partials/featuredIn';
@import '../partials/featuresExplained';
@import '../partials/featuresExtra';
@import '../partials/usedByPros';
@import '../partials/storeRating';
@import '../partials/testimonials';
@import '../partials/cta';
@import '../partials/privacyAndSecurity';

$color-bg: $color-deep-green;
$color-fg: $color-white;
$color-gradient-accent: $color-vivid-green;
$color-gradient-transparent: rgba(0, 0, 0, 0);

main {
    @include font-style-body;

    .intro {
        align-items: center;
        color: $color-fg;
        display: flex;
        flex-direction: row;
        gap: 24px;
        position: relative;

        &::before {
            background-color: $color-bg;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }

        &::after {
            background: radial-gradient(farthest-corner at 100% 100%, $color-gradient-accent 0%, $color-gradient-transparent 100%);
            bottom: 0;
            content: '';
            display: block;
            filter: blur(100px);
            height: 724px;
            opacity: .6;
            position: absolute;
            right: 0;
            width: 50%;
            z-index: -1;
        }

        .container {
            overflow-x: hidden;
            padding: 142px 60px;
            position: relative;

            & > div {
                flex: 0 0 50%;
            }
        }

        h1 {
            @include font-style-h0;

            margin-bottom: 30px;
            text-wrap: pretty;
        }

        p {
            margin-bottom: 30px;
        }

        .install-buttons-holder {
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: 50px;
            justify-content: center;
            margin-bottom: 30px;

            div {
                a#install-gmail {
                    box-shadow: 2px 4px 6px 0 rgba(0, 35, 128, .45);
                    font-size: 14px;
                    padding: 24px 20px;
                    width: 400px;
                }
            }
        }

        .hero-image img {
            width: 635px;
        }

        .hero-badges-container {
            display: flex;
            gap: 30px;
            margin-top: 30px;
        }

        .hero-badge {
            height: 70px;
        }
    }
}

@media screen and (width <= $size-M) {
    main {
        .intro {
            flex-direction: column-reverse;
        }

        .cta-home h2 {
            max-width: 85%;
        }
    }
}

@media screen and (width <= $size-S) {
    main {

        .intro {
            .container {
                padding: 40px 20px;

                & > div {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            .install-buttons-holder {
                display: none;
            }

            .hero-image img {
                max-width: 100%;
            }
        }

        .features-extra {
            flex-direction: column;
        }

        .cta-home h2 {
            max-width: 100%;
        }
    }
}
